import { Routes, Route } from "react-router-dom";


import Layout from "./components/Layout";

import Home from "./pages/Home";
import Encuestas from "./pages/Encuestas";
import Product from "./pages/Product";
import EncPerfilCliente from "./pages/encPerfilCliente";
import EncTeam from "./pages/encTeam";
import EncProyecto from "./pages/encProyecto";


function App() {
  return (
    <>
      {/* <Navbar></Navbar> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="encuestas/:id" element={<Encuestas />} />
          <Route path="product/*" element={<Product />} />          
          <Route path="encuestaCliente" element={<EncPerfilCliente />} />
          <Route path="encuestaTeam" element={<EncTeam />} />
          <Route path="encuestaProyecto" element={<EncProyecto />} />
          {/* <Route path="encuestaRedes" element={<EncPerfilCliente />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
