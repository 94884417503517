import "../assets/css/encuestaCliente.css";
import firebaseConfig from "../assets/js/firebase";

import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
// import { Database } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { initializeApp, firebase } from "firebase/app";
import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDatabase, ref, set, push } from "firebase/database";

import { useNavigate } from "react-router-dom";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";


const host = process.env.REACT_APP_POSTGRES_HOST;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const EncPerfilCliente = () => {
  const navigate = useNavigate();
  const nameRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isChecked, setIsChecked] = useState(
    searchParams.get("isChecked") === "true"
  );
  const [uid] = useState(uuidv4());
  const [clientName, setClientName] = useState("");
  const [respuestas, setRespuestas] = useState({
    ansCli1: "",
    ansCli2: "",
    ansCli3: "",
    ansCli4: "",
    ansCli5: "",
    ansCli6: "",
    ansCli7: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const clientNameSubmit = () => {
    const name = nameRef.current.value;
    setClientName(name);
  };

  const encuestaSubmit = () => {
    const database = getDatabase();
    const encuestaRef = ref(database, "encuestasCliente");
    const nuevaEncuestaRef = push(encuestaRef);
    set(nuevaEncuestaRef, {
      uid: uid,
      clientName: clientName,
      respuestas: respuestas,
    }).then(() => {
      toast.success("Respuestas guardadas, gracias!!!", {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      });
    });

    
    setIsSubmitted(true);
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    encuestaSubmit();
  };

  const answerSelected = (event) => {
    const { name, value } = event.target;
    setRespuestas((prevRespuestas) => ({ ...prevRespuestas, [name]: value }));
  };

  useEffect(() => {
    const checkboxValue = searchParams.get("isChecked");
    setIsChecked(checkboxValue === "true");
  }, [searchParams]);

  let name = "";
  let question = "";

  if (clientName === "" && !isSubmitted) {
    name = (
      <div className="d-flex flex-column justify-content-center">
        <div className="pt-5 datosClienteTit">Identifica tu cliente</div>
        <div className="h6 pt-4 datosCliente">
          Para comenzar, indica su nombre:
        </div>
        <form onSubmit={clientNameSubmit} className="sName pt-3">
          <input
            className="sNameInput"
            type="text"
            placeholder="Por favor escribe el nombre del cliente"
            ref={nameRef}
          />
        </form>
        <div className="subtitle text-uppercase">Empecemos!</div>
        <div className="divButton">
          <button className="btn btn-primary" onClick={clientNameSubmit}>
            Continuar <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </div>
      </div>
    );
  } else if (clientName !== "" && !isSubmitted) {
    name = (
      <div>
        <div className="titEncuestaCliente">
          {/* Bienvenido! {clientName} a la encuesta de clientes */}
        </div>
      </div>
    );
    question = (
      <div>
        <form className="preguntasEncCli" onSubmit={encuestaSubmit}>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">1</span>
              </strong>{" "}
              <div className="pregunta">
                Tiene claro el tipo de proyecto que requiere para satisfacer sus
                necesidades, conoce lo que este debe y no debe incluir.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Totalmente</div>
                <input
                  type="radio"
                  name="ansCli1"
                  value="Totalmente"
                  className="lvl5"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Bastante</div>
                <input
                  type="radio"
                  name="ansCli1"
                  value="Bastante"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Medio</div>
                <input
                  type="radio"
                  name="ansCli1"
                  value="Medio"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Algo</div>
                <input
                  type="radio"
                  name="ansCli1"
                  value="Algo"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Poco</div>
                <input
                  type="radio"
                  name="ansCli1"
                  value="Poco"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">2</span>
              </strong>{" "}
              <div className="pregunta">
                Responde las invitaciones, asiste a las reuniones de definicion
                y revision del avance del proyecto convocadas por el equipo de
                diseño.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli2"
                  value="Siempre"
                  className="lvl5"
                  onChange={answerSelected}
                />{" "}
              </div>
              <div className="niveles">
                <div className="nivelResp"> Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli2"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />{" "}
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli2"
                  value="A veces"
                  onChange={answerSelected}
                />{" "}
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli2"
                  value="Rara vez"
                  onChange={answerSelected}
                />{" "}
              </div>
              <div className="niveles">
                <div className="nivelResp"> Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli2"
                  value="Casi nunca"
                  onChange={answerSelected}
                />{" "}
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">3</span>
              </strong>{" "}
              <div className="pregunta">
                Aporta ideas e informacion importante para el proyecto mediante
                correos, mensajes o en las reuniones de revisión.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli3"
                  value="Siempre"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli3"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli3"
                  value="A veces"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli3"
                  value="Rara vez"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli3"
                  value="Casi nunca"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">4</span>
              </strong>{" "}
              <div className="pregunta">
                Muestra predisposicion para colaborar con el equipo de diseño
                buscando resolver de manera eficaz los problemas del proyecto.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli4"
                  value="Siempre"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli4"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli4"
                  value="A veces"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli4"
                  value="Rara vez"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli4"
                  value="Casi nunca"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">5</span>
              </strong>{" "}
              <div className="pregunta">
                Es flexible con los acuerdos iniciales (contrato) cuando se
                proponen mejoras al diseño del proyecto.
              </div>
            </label>

            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli5"
                  value="Siempre"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli5"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli5"
                  value="A veces"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli5"
                  value="Rara vez"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli5"
                  value="Casi nunca"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">6</span>
              </strong>{" "}
              <div className="pregunta">
                Requiere revisiones no programadas del avance del proyecto.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli6"
                  value="Siempre"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli6"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli6"
                  value="A veces"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli6"
                  value="Rara vez"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli6"
                  value="Casi nunca"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">7</span>
              </strong>{" "}
              <div className="pregunta">
                El cliente demuestra confianza en las promesas o declaraciones
                del equipo de diseño cuando se proponen cambios al diseño, el
                costo y el plazo.
              </div>
            </label>
            <div className="dFlexEnc">
              <div className="niveles">
                <div className="nivelResp">Siempre</div>
                <input
                  type="radio"
                  name="ansCli7"
                  value="Siempre"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Frecuentemente</div>
                <input
                  type="radio"
                  name="ansCli7"
                  value="Frecuentemente"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">A veces</div>
                <input
                  type="radio"
                  name="ansCli7"
                  value="A veces"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Rara vez</div>
                <input
                  type="radio"
                  name="ansCli7"
                  value="Rara vez"
                  onChange={answerSelected}
                />
              </div>
              <div className="niveles">
                <div className="nivelResp">Casi nunca</div>
                <input
                  type="radio"
                  name="ansCli7"
                  value="Casi nunca"
                  onChange={answerSelected}
                />
              </div>
            </div>
          </div>
          <div className="text-center pb-5">
            <input
              className="feedback-button btn btn-primary"
              type="submit"
              value="Enviar"
            />
          </div>
        </form>
        
      </div>
    );
  }

  return (
    <div>
      {isChecked ? (
        <div>
          {name}

          {question}
        </div>
      ) : (
        // Mostrar mensaje si el checkbox es falso
        <h1>No ha aceptado las condiciones para contestar la encuesta</h1>
      )}
    </div>
  );
};

export default EncPerfilCliente;
