import { Link } from "react-router-dom";

// Link;

const ProductsList = ({ products }) => {
  console.log("llegue aca mijin");
  console.log(products);
  return (
    <ul>
      {products.map((product) => {
        return (
          <li key={product.id}>
            <h4>{product.title}</h4>
            <Link to={`/product/${product.id}`}>more details ...</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default ProductsList;
