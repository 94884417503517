import "../assets/css/encuestaProyecto.css";
import firebaseConfig from "../assets/js/firebase";

import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
// import { Database } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { initializeApp, firebase } from "firebase/app";
import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDatabase, ref, set, push } from "firebase/database";

import { useNavigate } from "react-router-dom";

import { faAnglesRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";
import imgInfo from "../assets/img/info.png";
import loadAllPaises from "../utilities/loadAllPaises";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

console.log("liad");
console.log(loadAllPaises);

const EncProyecto = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const nameRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const cargarRespuestasJSON = () => {
    const respuestasJSON = localStorage.getItem("respuestas");
    if (respuestasJSON) {
      return JSON.parse(respuestasJSON);
    }
    return {};
  };

  const [respuestas, setRespuestas] = useState(cargarRespuestasJSON());
  const [isChecked, setIsChecked] = useState(
    searchParams.get("isChecked") === "true"
  );
  const [uid] = useState(uuidv4());
  const [clientName, setClientName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const clientNameSubmit = () => {
    const name = nameRef.current.value;
    setClientName(name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    encuestaSubmit();
  };

  const encuestaSubmit = () => {
    const database = getDatabase();
    const encuestaRef = ref(database, "encuestasProyecto");
    const nuevaEncuestaRef = push(encuestaRef);
    set(nuevaEncuestaRef, {
      uid: uid,
      clientName: clientName,
      respuestas: respuestas,
    }).then(() => {
      toast.success("Respuestas guardadas, gracias!!!", {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      });
    });

    setIsSubmitted(true);
    navigate("/");
  };

  const answerSelected = (event) => {
    const { name, value } = event.target;

    if (name === "ansProy1") {
      const country = JSON.parse(value);
      setSelectedCountry(country);
    }

    // Actualiza el estado respuestas con los nuevos valores
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [name]: value,
    }));

    console.log(respuestas);
  };

  useEffect(() => {
    const checkboxValue = searchParams.get("isChecked");
    setIsChecked(checkboxValue === "true");
  }, [searchParams]);

  // Tooltip

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  // Fin tooltip

  let name = "";
  let question = "";

  if (clientName === "" && !isSubmitted) {
    name = (
      <div className="d-flex flex-column justify-content-center">
        <div className="pt-5 datosClienteTit">Identifica tu empresa</div>
        <div className="h6 pt-4 datosCliente">
          Para comenzar, indicar su nombre:
        </div>
        <form onSubmit={clientNameSubmit} className="sName pt-3">
          <input
            className="sNameInput"
            type="text"
            placeholder="Por favor escribe el nombre del proyecto"
            ref={nameRef}
          />
        </form>
        <div className="subtitle text-uppercase">Empecemos!</div>
        <div className="divButton">
          <button className="btn btn-primary" onClick={clientNameSubmit}>
            Continuar <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </div>
      </div>
    );
  } else if (clientName !== "" && !isSubmitted) {
    name = (
      <div>
        <div className="titEncuestaCliente">
          {/* Bienvenido! {clientName} a la encuesta de clientes */}
        </div>
      </div>
    );
    question = (
      <div className="">
        <form className="preguntasEncCli padTop2" onSubmit={handleSubmit}>
          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">UBICACIÓN DEL PROYECTO</p>
            </div>
            <div className="column ">
              {/* <input
                className="inputText"
                type="text"
                placeholder="País, ciudad"
                name="ansProy1"
                onChange={answerSelected}
              /> */}
              <select
                className="inputText"
                name="ansProy1"
                onChange={answerSelected}
              >
                <option value="">Selecciona un país</option>
                {loadAllPaises.map((pais, index) => (
                  <option key={index} value={JSON.stringify(pais)}>
                    {pais.country}
                  </option>
                ))}
              </select>
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Ubicación física donde se llevó a cabo el proyecto de
                    construcción.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">TIPO DE PROYECTO</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Detalles del proyecto"
                name="ansProy2"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Clasificación del proyecto de construcción según su
                    naturaleza o finalidad.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">ENTIDAD REVISORA o APROBADORA (Municipio)</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Organismo encargado de revisar y aprobar proyecto"
                name="ansProy3"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Organismo gubernamental encargado de revisar y aprobar los
                    aspectos legales y técnicos del proyecto en el ámbito
                    municipal.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">m² DE DISEÑO TOTALES DE PROYECTO</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Area en m²"
                name="ansProy4"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Área total, en metros cuadrados, que abarcará el diseño y la
                    construcción del proyecto.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">VALOR DE VENTA DEL PROYECTO</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder={`Monto en ${
                  selectedCountry ? selectedCountry.currency : "dolares"
                }`}
                name="ansProy5"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Monto económico estimado para la venta o comercialización
                    del proyecto ejecutivo una vez finalizado.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">TIEMPO TOTAL DEDICADO AL PROYECTO (HH)</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="TIEMPO HORAS HOMBRE"
                name="ansProy6"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Cantidad total de horas hombre invertidas en el proyecto
                    desde su inicio hasta su finalización. HORAS HOMBRE (HH): se
                    refiere a la cantidad de tiempo dedicado al trabajo por una
                    persona. Esta medida toma en cuenta las horas de trabajo de
                    un individuo. Por ejemplo, si un proyecto requiere que tres
                    personas trabajen durante 10 horas cada una, el total de
                    horas hombre sería de 30 (3 personas x 10 horas).
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                NÚMERO DE REVISIONES REQUERIDAS POR EL CLIENTE
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Revisiones requeridas"
                name="ansProy7"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Cantidad de revisiones solicitadas por el cliente para
                    realizar modificaciones o mejoras en el proyecto.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                NÚMERO DE REVISIONES REQUERIDAS POR LA ENTIDAD REVISORA O
                APROBADORA DEL PROYECTO
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Organismo encargado de revisar y aprobar proyecto"
                name="ansProy8"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Cantidad de revisiones solicitadas por la entidad revisora o
                    aprobadora para verificar el cumplimiento de normativas y
                    reglamentos.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                CANTIDAD DE VERSIONES DE DISEÑO DEL PROYECTO
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Versiones del diseño"
                name="ansProy9"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Número de iteraciones o cambios realizados en el diseño del
                    proyecto antes de su versión final.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                DURACIÓN REAL DE PROYECTO DESDE QUE SE FIRMA EL CONTRATO HASTA
                QUE SE ENTREGA EL PROYECTO EJECUTIVO
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Duración del proyecto"
                name="ansProy10"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Período de tiempo transcurrido desde la firma del contrato
                    hasta la entrega del proyecto ejecutivo. Se consideran en
                    este punto retrasos o imprevistos.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">DURACIÓN PLANIFICADA</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Duración estimada"
                name="ansProy11"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Duración estimada y planificada para la ejecución completa
                    del proyecto, considerando todas las etapas y actividades.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">CANTIDAD DE TAREAS (BIM) REALIZADAS</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Número de tareas ejecutadas"
                name="ansProy12"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Número de tareas ejecutadas utilizando el modelado de
                    información de construcción (BIM, por sus siglas en inglés)
                    en el desarrollo del proyecto.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">HORAS TRABAJAS EN PROYECTO</p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Total de horas trabajadas"
                name="ansProy13"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Cantidad total de horas trabajadas por el equipo de oficina
                    para llegar al proyecto ejecutivo.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                PRECIO PREVISTO{" ("}
                {` ${selectedCountry ? selectedCountry.symbol : "$"}`}
                {") "}
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Precio estimado o presupuestado"
                name="ansProy14"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Precio estimado o presupuestado para la realización del
                    proyecto antes de su ejecución.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="column alignRigth">
              <p className="pt-1r">
                PRECIO FINAL REAL{" ("}
                {` ${selectedCountry ? selectedCountry.symbol : "$"}`}
                {") "}
              </p>
            </div>
            <div className="column ">
              <input
                className="inputText"
                type="text"
                placeholder="Precio final real"
                name="ansProy15"
                onChange={answerSelected}
              />
            </div>
            <div className="column column2">
              <div
                className="tooltipCust"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img src={imgInfo} alt="Información" className="img-small" />
                {showTooltip && (
                  <span className="tooltiptext">
                    Precio final real del proyecto después de considerar todos
                    los costos y gastos relacionados con posibles cambios o
                    imprevistos del proyecto.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="text-center pb-5">
            <input
              className="feedback-button btn btn-primary"
              type="submit"
              value="Enviar"
            />
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      {isChecked ? (
        <div>
          {name}

          {question}
        </div>
      ) : (
        // Mostrar mensaje si el checkbox es falso
        <h1>No ha aceptado las condiciones para contestar la encuesta</h1>
      )}
    </div>
  );
};

export default EncProyecto;
