import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image, Button, Footer } from "react-bootstrap";
import { useState, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

import "../assets/css/encuestas.css";

const Encuestas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    if (id === "cliente" || id === "proyecto") {
      setContinueCliente(false);
      setIsChecked(true);
    }

    if (id === "organizacion") {
      setIsChecked(false);
      setContinueCliente(true);
    }
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  const [text, setText] = useState(
    "Bienvenido(a). \n\nEsta encuesta forma parte de un estudio que busca entender la influencia de las características del cliente (propietario, mandante) en el desempeño del proceso de diseño de proyectos de construcción. Agradecemos su participación y colaboración con este estudio.\n\n¿En qué consiste esta encuesta? \n\nEl objetivo de esta encuesta es conocer las características de su cliente relacionadas con su conocimiento del alcance del proyecto, comunicación, colaboración y confianza con el equipo de trabajo. Por lo que es imprescindible contar con su participación, respondiendo con sinceridad las preguntas. \n\nConfidencialidad. \n\nEl equipo responsable de este estudio, garantiza que sus respuestas a la ****encuesta**** serán usadas para realizar análisis estadísticos resguardando la confidencialidad de estos. La empresa no podrá utilizar su información personal para fines externos a los del estudio."
  );
  const [isChecked, setIsChecked] = useState(true);
  const [showInputs, setShowInputs] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [continueCliente, setContinueCliente] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleButtonClick = () => {
    console.log("aca llega el contiunuas");
    console.log(continueCliente);

    if (!continueCliente) {
      setContinueCliente(!continueCliente);
      setIsChecked(!isChecked);
    } else {
      setShowButtons(false);
      setShowInputs(false);

      if (id === "cliente") {
        navigate("/encuestaCliente?isChecked=true");
      }
      if (id === "organizacion") {
        navigate("/encuestaTeam?isChecked=true");
      }
      if (id === "proyecto") {
        navigate("/encuestaProyecto?isChecked=true");
      }
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
    >
      {showInputs && id === "cliente" && !continueCliente && (
        <React.Fragment>
          <div className="h5 condicionesIni">
            <em>
              A continuación usted encontrará una serie de preguntas donde se le
              solicitará indicar las características que usted ha observado en
              el contratante (mandante) de su proyecto de. Las respuestas están
              asociadas a una escala que va desde menor .
            </em>
          </div>
          <div className="h5 condicionesIni pt-4 pb-4">
            <em>
              Por favor lea atentamente cada pregunta y tome el tiempo necesario
              para responder.
            </em>
          </div>
        </React.Fragment>
      )}

      {showInputs && id === "proyecto" && !continueCliente && (
        <React.Fragment>
          <div className="h3">INSTRUCCIONES</div>
          <div className="h5 condicionesIni">
            <em>
              Para poder brindarte un servicio eficiente y personalizado,
              necesitamos recopilar cierta información importante. Por favor,
              completa los siguientes campos en el formulario adjunto para que
              podamos entender tu empresa. Por favor lea atentamente cada ítem y
              tome el tiempo necesario para responder. En el lateral encontrará
              un icono de información y también un video de la información
              solicitada.  
            </em>
          </div>
          <div className="h5 condicionesIni pt-4 pb-4">
            <em>Completar el formulario debería tomar 5 a 10 minutos.</em>
          </div>
        </React.Fragment>
      )}

      {showInputs && id === "organizacion" && (
        <div className="text-center">
          <div className="condiciones">
            <div className=" h3 textTit">Bienvenido(a)</div>
            <div>
              Esta encuesta forma parte de un estudio que busca entender la
              influencia de las características del cliente (propietario,
              mandante) en el desempeño del proceso de diseño de proyectos de
              construcción. Agradecemos su participación y colaboración con este
              estudio.
            </div>
            <div className="textTit">¿En qué consiste esta encuesta?</div>
            <div>
              El objetivo de esta encuesta es conocer las características de su
              cliente relacionadas con su conocimiento del alcance del proyecto,
              comunicación, colaboración y confianza con el equipo de trabajo.
              Por lo que es imprescindible contar con su participación,
              respondiendo con sinceridad las preguntas.{" "}
            </div>
            <div className="textTit">Confidencialidad. </div>
            <div>
              El equipo responsable de este estudio, garantiza que sus
              respuestas a la encuesta serán usadas para realizar análisis
              estadísticos resguardando la confidencialidad de estos. 
              <strong>
                La empresa no podrá utilizar su información personal para fines
                externos a los del estudio.
              </strong>
               {" "}
            </div>
            <div className="textTit">INSTRUCCIONES:</div>
            <div>Completar la encuesta debería tomar 5 a 10 minutos.</div>
            <div>
              Nótese que ciertas personas llevan una nomenclatura tipo PE-
              (Personal Externo) delante de su nombre. Éstos son los
              profesionales involucrados en otras organizaciones pertenecientes
              a un proyecto actualmente en desarrollo. Si interactúa con alguno
              de ellos los deberá considerar como parte de su organización..
            </div>
            <div>
              {" "}
              Si usted se encuentra categorizado con la nomenclatura PE-
              (Personal Externo) deberá marcar a TODOS los profesionales con
              quien se relaciona para el desarrollo del proyecto (oficina de
              arquitectura, oficinas de especialidades, propietarios, etc.).
            </div>
          </div>
          {/* <textarea
            name="text"
            value={text}
            onChange={handleInputChange}
            rows="20"
            cols="100"
            className="textCondiciones"
            readOnly={true}
          /> */}
          <br />
          <div
            className="form-check d-flex align-items-center justify-content-center"
            style={{ margin: "10px 0" }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "5px" }}
              checked={isChecked}
              onClick={handleCheckboxChange}
            >
              <strong>He leído y acepto los términos y condiciones</strong>
            </label>
          </div>
          <br />
        </div>
      )}

      {showInputs && id === "cliente" && continueCliente && (
        <div className="text-center">
          <div className="condiciones">
            <div className=" h3 textTit">Bienvenido(a)</div>
            <div>
              Esta encuesta forma parte de un estudio que busca entender la
              influencia de las características del cliente (propietario,
              mandante) en el desempeño del proceso de diseño de proyectos de
              construcción. Agradecemos su participación y colaboración con este
              estudio.
            </div>
            <div className="textTit">¿En qué consiste esta encuesta?</div>
            <div>
              El objetivo de esta encuesta es conocer las características de su
              cliente relacionadas con su conocimiento del alcance del proyecto,
              comunicación, colaboración y confianza con el equipo de trabajo.
              Por lo que es imprescindible contar con su participación,
              respondiendo con sinceridad las preguntas.{" "}
            </div>
            <div className="textTit">Confidencialidad. </div>
            <div>
              El equipo responsable de este estudio, garantiza que sus
              respuestas a la encuesta serán usadas para realizar análisis
              estadísticos resguardando la confidencialidad de estos. 
              <strong>
                La empresa no podrá utilizar su información personal para fines
                externos a los del estudio.
              </strong>
               {" "}
            </div>
            <div className="textTit">INSTRUCCIONES:</div>
            <div>Completar la encuesta debería tomar 5 a 10 minutos.</div>
          </div>
          {/* <textarea
            name="text"
            value={text}
            onChange={handleInputChange}
            rows="20"
            cols="100"
            className="textCondiciones"
            readOnly={true}
          /> */}
          <br />
          <div
            className="form-check d-flex align-items-center justify-content-center"
            style={{ margin: "10px 0" }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "5px" }}
              checked={isChecked}
              onClick={handleCheckboxChange}
            >
              <strong>He leído y acepto los términos y condiciones</strong>
            </label>
          </div>
          <br />
        </div>
      )}

      {showInputs && id === "proyecto" && continueCliente && (
        <div className="text-center">
          <div className="condiciones">
            <div className=" h3 textTit">Bienvenido(a)</div>
            <div>
              En nombre del proyecto de investigación CONSTRURED, valoramos y
              respetamos tu privacidad y la confidencialidad de la información
              que compartes con nosotros. Reconocemos que los datos
              proporcionados son sensibles y nos comprometemos a protegerlos de
              acuerdo con las leyes y regulaciones aplicables.
            </div>
            <div className="textTit">Confidencialidad</div>
            <div>
              Toda la información recopilada a través de este formulario será
              utilizada exclusivamente con el propósito de evaluar y brindar una
              propuesta acorde a tus necesidades. No compartiremos tus datos
              personales ni comerciales con terceros sin tu consentimiento
              expreso.  La empresa no podrá utilizar su información personal
              para fines externos a los del estudio. {" "}
            </div>
            
            <div className="pt-4">
              Además, implementamos medidas de seguridad para proteger tus datos
              contra accesos no autorizados, modificaciones o divulgaciones no
              deseadas. Si tienes alguna pregunta o inquietud sobre nuestra
              política de privacidad o el manejo de tus datos, no dudes en
              contactarnos. Estaremos encantados de brindarte toda la
              información que necesites. Gracias por tu confianza.  {" "}
            </div>
            
            <div className="pt-5">Atentamente,</div>
            <div>El equipo de CONSTRURED</div>
          </div>
          {/* <textarea
            name="text"
            value={text}
            onChange={handleInputChange}
            rows="20"
            cols="100"
            className="textCondiciones"
            readOnly={true}
          /> */}
          <br />
          <div
            className="form-check d-flex align-items-center justify-content-center"
            style={{ margin: "10px 0" }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "5px" }}
              checked={isChecked}
              onClick={handleCheckboxChange}
            >
              <strong>He leído y acepto los términos y condiciones</strong>
            </label>
          </div>
          <br />
        </div>
      )}

      {showInputs && id === "redes" && (
        <div className="text-center">
          <div />

          {/* <textarea
            name="text"
            value={text}
            onChange={handleInputChange}
            rows="20"
            cols="100"
            className="textCondiciones"
            readOnly={true}
          /> */}
          <br />
          <div
            className="form-check d-flex align-items-center justify-content-center"
            style={{ margin: "10px 0" }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "5px" }}
              checked={isChecked}
              onClick={handleCheckboxChange}
            >
              He leído y acepto los términos y condiciones
            </label>
          </div>
          <br />
        </div>
      )}

      {showButtons ? (
        <button
          className="btn btn-primary"
          disabled={!isChecked}
          onClick={handleButtonClick}
        >
          Continuar <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      ) : (
        <div className="d-flex justify-content-center">
          <Link
            to={`/encuesta?isChecked=${isChecked}`}
            className="btn btn-primary"
          >
            Perfil cliente
          </Link>
          <Link
            to={`/encuesta?isChecked=${isChecked}`}
            className="btn btn-primary"
          >
            Redes sociales
          </Link>
        </div>
      )}
    </div>
  );
};

export default Encuestas;
