import "../assets/css/encuestaTeam.css";
import firebaseConfig from "../assets/js/firebase";

import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";
import { initializeApp, firebase } from "firebase/app";
import React, { useRef, useState, useEffect, createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getDatabase,
  ref,
  set,
  push,
  query,
  orderByChild,
  equalTo,
  get,
  update,
  child,
} from "firebase/database";

import { useNavigate } from "react-router-dom";
import { faAnglesRight, faSave } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const EncTeam = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  let people = [
    { nombre: "tres", area: "arquitectonia" },
    { nombre: "tres2", area: "arquitectoni4a" },
  ];
  const nameRef = useRef();
  const integrantsRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isChecked, setIsChecked] = useState(
    searchParams.get("isChecked") === "true"
  );
  const [uid] = useState(uuidv4());
  const [clientName, setClientName] = useState(null);
  const [respuestas, setRespuestas] = useState({
    ansTeam1: "",
    ansTeam2: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [listParticipants, setListParticipants] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [areaSeleccionada, setAreaSeleccionada] = useState("");
  const [integranteSeleccionada, setIntegranteSeleccionada] = useState("");
  // const [integrantes, setintegrantes] = useState([
  //   { id: 0, nombre: "----------" },
  // ]);
  const selectRef = useRef(null);
  let integranteSeleccionado = { id: 0 };

  const Integrante = ({ id, nombre, area }) => (
    <tr>
      <td>{id}</td>
      <td>{nombre}</td>
      <td>{area}</td>
    </tr>
  );

  const [integrantes, setIntegrantes] = useState([]);
  const [numberInteg, setNumberInteg] = useState(0);
  const [uidGuardado, setUidGuardado] = useState("");

  const handleChange = (event) => {
    const integs = event.target.value;

    // Verifica si el valor es un número
    if (!Number.isNaN(integs)) {
      // Verifica si el valor es mayor o igual a 0
      if (integs >= 0) {
        // Asigna el valor al estado
        setNumberInteg(integs);
      } else {
        // Asigna el valor por defecto
        setNumberInteg(0);
      }
    } else {
      // Asigna el valor por defecto
      setNumberInteg(0);
    }

    // Crea los integrantes
    const integrantes = creaIntegrantes(integs);
    // Asigna los integrantes al estado
    setIntegrantes(integrantes);
  };

  const handleChangeArea = (event) => {
    const areaSeleccionada = event.target.value;

    // Asigna el área seleccionada al estado
    setAreaSeleccionada(areaSeleccionada);
  };

  const handleChangeIntegrante = (event) => {
    const integranteSeleccionada = event.target.value;

    // Asigna el área seleccionada al estado
    setIntegranteSeleccionada(integranteSeleccionada);
  };

  const creaIntegrantes = (numeroIntegrantes) => {
    const nuevosIntegrantes = [{ id: 0, nombre: "Seleccione el integrante" }];
    for (let i = 1; i <= numeroIntegrantes; i++) {
      nuevosIntegrantes.push({
        id: i,
        nombre: `Integrante${i}`,
        area: "",
      });
    }
    return nuevosIntegrantes;
  };

  useEffect(() => {
    let nuevasOficinas = [];
  }, []);

  const clientNameSubmit = (event) => {
    let name = "";
    if (!clientName) {
      event.preventDefault();
      name = nameRef.current.value;
      setClientName(name);

      const database = getDatabase(app);
      const encuestaRef = ref(database, "encuestasTeam");

      const queryT = query(
        encuestaRef,
        orderByChild("clientName"),
        equalTo(name)
      );

      get(queryT).then((snapshot) => {
        if (snapshot.exists()) {
          const encuestas_coincidentes = snapshot.val();
          const key = Object.keys(encuestas_coincidentes)[0];
          const uid = key[0];
          const respuestas = encuestas_coincidentes[key].respuestas;

          const nuevosIntegrantes = {
            id: 0,
            nombre: "Seleccione el integrante",
          };
          const integrantesActualizados = [nuevosIntegrantes, ...respuestas];
          setIntegrantes(integrantesActualizados);

          setUidGuardado(uid);
          setClientName(name);
          setIsSubmitted(false);
          setListParticipants(true);
          setIsChecked(true);

          // Puedes hacer algo más con los resultados aquí
        } else {
          // Maneja el caso en que no se encuentran encuestas coincidentes
          setClientName(name);
          console.error("No se encontraron encuestas coincidentes.");
        }
      });
    }

    if (clientName) {
      setListParticipants(true);
    }
  };

  const saveIntegrant = (item) => {
    const integrante = integrantes.find(
      (integrante) =>
        parseInt(integrante.id) === parseInt(integranteSeleccionada)
    );

    // Si el integrante existe
    if (integrante) {
      // Agrega la palabra "test" al área del integrante
      integrante.area = areaSeleccionada;

      // Recargá el valor del primer select
      setIntegrantes([...integrantes]);
    }
  };

  const getListParticipants = () => {
    const listParticipants = people.length();
    setListParticipants(listParticipants);
  };

  const updateFirebaseRecord = async (uid, newData) => {
    try {
      const database = getDatabase();
      const encuestaRef = ref(database, "encuestasTeam");
      const encuestaToUpdateRef = ref(encuestaRef, uid);

      await update(encuestaToUpdateRef, newData);
    } catch (error) {
      console.error("Error al actualizar el registro en Firebase:", error);
    }
  };

  const encuestaSubmit = async () => {
    const fechaActual = new Date();

    const formattedDate = `${fechaActual
      .getDate()
      .toString()
      .padStart(2, "0")}/${(fechaActual.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${fechaActual.getFullYear()} ${fechaActual
      .getHours()
      .toString()
      .padStart(2, "0")}:${fechaActual
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    // Si uidGuardado no está vacío, significa que ya se encontró una coincidencia previa

    if (uidGuardado !== "") {
      // const encuestaToUpdateRef = ref(encuestaRef, uidGuardado);

      const newintegrantes = [...integrantes]; // Copia el array original

      const updatedintegrantes = newintegrantes.filter(
        // Elimina los que no tengan respuestas
        (item) => Object.keys(item).length > 2
      );

      const dataToUpdate = {
        fecha: formattedDate,
        clientName: clientName,
        // Actualiza el campo respuestas
        respuestas: updatedintegrantes,
      };

      // updateFirebaseRecord(uidGuardado, dataToUpdate);
      try {
        let dbRef = ref(db, "encuestasTeam/" + uidGuardado);
        set(dbRef, dataToUpdate);
        setIsSubmitted(true);
        toast.success("Respuestas guardadas, gracias!!! Id: " + clientName, {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        });
        navigate("/");
      } catch (error) {
        console.error("Error al actualizar registro:", error);
      }
    } else {
      const newintegrantes = [...integrantes]; // Copia el array original

      const updatedintegrantes = newintegrantes.filter(
        // Elimina los que no tengan respuestas
        (item) => Object.keys(item).length > 2
      );

      const dataToSubmit = {
        fecha: formattedDate,
        clientName: clientName,
        // Actualiza el campo respuestas
        respuestas: updatedintegrantes,
      };

      try {
        const dbRef = ref(db, "encuestasTeam");
        const newEncuestaRef = push(dbRef);
        set(newEncuestaRef, dataToSubmit);
        setIsSubmitted(true);
        toast.success("Respuestas guardadas, gracias!!!Id: " + clientName, {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        });
        navigate("/");
      } catch (error) {
        console.error("Error al guardar registro:", error);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    encuestaSubmit();
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    // Obtener los datos del objeto seleccionado en integrantes
    const selectedOptionData = integrantes.find(
      (item) => item.nombre === selectedOption
    );

    if (selectedOptionData) {
      // Establecer los valores de los radios según los datos de integrantes
      const radioInputs = document.querySelectorAll('input[type="radio"]');
      radioInputs.forEach((input) => {
        if (
          (input.name === "ansTeam1" &&
            input.value === selectedOptionData.ansTeam1) ||
          (input.name === "ansTeam2" &&
            input.value === selectedOptionData.ansTeam2)
        ) {
          input.checked = true;
        } else {
          input.checked = false;
        }
      });

      setRespuestas({
        ansTeam1: selectedOptionData.ansTeam1 || "",
        ansTeam2: selectedOptionData.ansTeam2 || "",
      });
    }
  };

  const answerSelected = (event) => {
    const { name, value } = event.target;

    setIntegrantes((prevListado) =>
      prevListado.map((item) => {
        if (item.nombre === selectedValue) {
          return { ...item, [name]: value };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    const checkboxValue = searchParams.get("isChecked");
    setIsChecked(checkboxValue === "true");
  }, [searchParams]);

  useEffect(() => {
    const integrantesJSON = JSON.stringify(integrantes);
    localStorage.setItem("integrantes", integrantesJSON);
  }, [integrantes]);

  useEffect(() => {
    const storedintegrantes = localStorage.getItem("integrantes");
    if (storedintegrantes) {
      setIntegrantes(JSON.parse(storedintegrantes));
    }
  }, []);

  let name = "";
  let question = "";

  if (!clientName && !isSubmitted) {
    name = (
      <div className="d-flex flex-column justify-content-center">
        <div className="pt-5 datosClienteTit">Evalúa tu organización</div>
        <div className="h6 pt-4 datosCliente">
          Para comenzar, indicar su nombre:
        </div>
        <form onSubmit={clientNameSubmit} className="sName pt-3">
          <input
            className="sNameInput"
            type="text"
            placeholder="Por favor escribe el nombre del cliente"
            ref={nameRef}
          />
        </form>
        <div className="subtitle text-uppercase">Empecemos!</div>
        <div className="divButton">
          <button className="btn btn-primary" onClick={clientNameSubmit}>
            Continuar <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </div>
      </div>
    );
  } else if (clientName && !isSubmitted && listParticipants) {
    name = (
      <div>
        <div className="titEncuestaCliente">
          {/* Bienvenido! {clientName} a la encuesta de clientes */}
        </div>
      </div>
    );
    question = (
      <div>
        <form className="preguntasEncCli" onSubmit={encuestaSubmit}>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">1</span>
              </strong>{" "}
              <div className="pregunta">
                Indique con cuáles de las siguientes personas usted ha tenido
                alguna INTERACCIÓN en el trabajo durante los últimos 6 meses.
              </div>
            </label>
            <div>
              La INTERACCIÓN es una acción comunicativa que se desarrolla de
              modo recíproco. Ejemplos de interacción: conversación telefónica,
              intercambio de mail efectivo (con una respuesta del otro),
              conversación en directo respecto a una situación relativa al
              trabajo y/o reunión de trabajo. NO se considera como una
              interacción: Mail masivo en que no se recibe respuestas, anuncio o
              presentación en la cual sólo el presentador es quien habla, etc.
            </div>
            <div className="padSelect">
              <select
                className="custom-select"
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {integrantes.map((item) => {
                  let classItem = ""; // Clase CSS por defecto

                  if (item.ansTeam1 && item.ansTeam2) {
                    classItem = "colorVerde"; // Asignar clase "colorVerde" si ambas propiedades están presentes
                  } else if (item.ansTeam1 || item.ansTeam2) {
                    classItem = "colorAmarillo"; // Asignar clase "colorAmarillo" si alguna de las propiedades está presente
                  } else if (item.id === 0) {
                    classItem = "colorDefault"; // Asignar clase "colorAmarillo" si alguna de las propiedades está presente
                  } else {
                    classItem = "colorRojo"; // Asignar clase "colorRojo" si ninguna de las propiedades está presente
                  }

                  return (
                    <option
                      key={item.id}
                      value={item.nombre}
                      className={classItem}
                    >
                      {item.nombre}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">2</span>
              </strong>{" "}
              <div className="pregunta">
                De las personas anteriormente citadas por usted, indique con qué
                frecuencia trata usted información relevante relacionada con su
                trabajo.
              </div>
            </label>
            <div className="padSelect">
              {" "}
              <input
                className="custom"
                type="text"
                value={selectedValue}
                onChange={answerSelected}
              />{" "}
            </div>
            <div className="dFlexEnc">
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="Nunca"
                  className="lvl5"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">Nunca</div>
                <div className="nivelRespSub">Una vez al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="A veces"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">A veces</div>
                <div className="nivelRespSub">Una a tres veces al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="Ocasionalmente"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">Ocasionalmente</div>
                <div className="nivelRespSub">Tres a seis veces al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="Poco Frecuente"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">Poco Frecuente</div>
                <div className="nivelRespSub">Dos a tres veces a la semana</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="Frecuente"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">Frecuente</div>
                <div className="nivelRespSub">
                  Tres a cinco veces a la semana
                </div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam1"
                  value="Siempre"
                  onChange={answerSelected}
                />{" "}
                <div className="nivelResp">Siempre</div>
                <div className="nivelRespSub">Más de una vez al día</div>
              </div>
            </div>
          </div>
          <div className="pbot-2">
            <label className="pregLabel">
              <strong>
                <span className="number">3</span>
              </strong>{" "}
              <div className="pregunta">
                Indique con qué frecuencia desarrolla ideas innovadoras
                laborales con las siguientes personas.
              </div>
            </label>
            <div className="padSelect">
              {" "}
              <input
                className="custom"
                type="text"
                value={selectedValue}
                onChange={answerSelected}
              />{" "}
            </div>
            <div className="dFlexEnc">
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="Nunca"
                  onChange={answerSelected}
                />
                <div className="nivelResp">Nunca</div>
                <div className="nivelRespSub">Una vez al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="A veces"
                  onChange={answerSelected}
                />
                <div className="nivelResp">A veces</div>
                <div className="nivelRespSub">Una a tres veces al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="Ocasionalmente"
                  onChange={answerSelected}
                />
                <div className="nivelResp">Ocasionalmente</div>
                <div className="nivelRespSub">Tres a seis veces al mes</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="Poco Frecuente"
                  onChange={answerSelected}
                />
                <div className="nivelResp">Poco Frecuente</div>
                <div className="nivelRespSub">Dos a tres veces a la semana</div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="Frecuente"
                  onChange={answerSelected}
                />
                <div className="nivelResp">Frecuente</div>
                <div className="nivelRespSub">
                  Tres a cinco veces por semana
                </div>
              </div>
              <div className="niveles">
                <input
                  type="radio"
                  name="ansTeam2"
                  value="Siempre"
                  onChange={answerSelected}
                />
                <div className="nivelResp">Siempre</div>
                <div className="nivelRespSub">Más de una vez al dia</div>
              </div>
            </div>
          </div>

          <div className="text-center pb-5">
            <input
              className="feedback-button btn btn-primary"
              type="submit"
              value="Enviar"
            />
          </div>
        </form>
      </div>
    );
  } else if (clientName && !isSubmitted && !listParticipants) {
    name = (
      <div className="d-flex flex-column justify-content-center">
        <div className="pt-5 datosClienteTit" style={{ fontSize: "1.5rem" }}>
          Indique el número de personas que formaron parte para la elaboración
          de proyecto
        </div>
        <div className="h6 pt-4 datosCliente d-flex">
          <div className="pt-2">Integrantes :</div>
          <div>
            <form className="sName ">
              <input
                className="sNameInput"
                type="number"
                placeholder="Por favor escribe el numero de integrantes"
                onChange={handleChange}
              />
            </form>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-3 p-5">
          <div className="p-2">
            <label htmlFor="integrante">
              Seleccione el integrante del equipo de trabajo:
            </label>
            <select
              id="integrante"
              className="form-select"
              ref={selectRef}
              value={null}
              onChange={handleChangeIntegrante}
            >
              {integrantes.map((opcion) => (
                <option key={opcion.id} value={opcion.id}>
                  {opcion.nombre}
                  {opcion.area ? <span> - {opcion.area}</span> : null}
                </option>
              ))}
            </select>
          </div>
          <div className="p-2">
            <label htmlFor="areas">
              Área a la que se dedicó el integrante:
            </label>
            <select
              name="areas"
              id="areas"
              className="form-select"
              onChange={handleChangeArea}
            >
              <option value="">Elija el area de trabajo:</option>
              <option value="Arquitectónico">Arquitectónico</option>
              <option value="Estructural">Estructural</option>
              <option value="Mecánica">Mecánica</option>
              <option value="Eléctrica">Eléctrica</option>
              <option value="Electrónica">Electrónica</option>
              <option value="Inst. Sanitarias y Pluviales">
                Inst. Sanitarias y Pluviales
              </option>
              <option value="Inst. Hidráulicas">Inst. Hidráulicas</option>
            </select>
          </div>
          <div className="p-2 d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={saveIntegrant}
              title="Guardar integrante"
            >
              <FontAwesomeIcon icon={faSave} />
              <span className="ms-2">Guardar</span>
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-3">
          {integrantes.length > 0 && (
            <table style={{ width: "70%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Id
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Integrante
                  </th>
                  <th
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Area
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: "#e0e0e0", textAlign: "center" }}
              >
                {integrantes
                  .filter((integrante) => integrante.id > 0)
                  .map((integrante, index) => (
                    <Integrante
                      key={integrante.nombre}
                      {...integrante}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "white",
                        border: "1px solid #ddd",
                        padding: "8px",
                        borderBottom: "2px solid #000",
                        borderTop: "2px solid #000",
                      }}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="divButton p-5">
          <button className="btn btn-primary" onClick={clientNameSubmit}>
            Continuar <FontAwesomeIcon icon={faAnglesRight} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {isChecked ? (
        <div>
          {name}

          {question}
        </div>
      ) : (
        // Mostrar mensaje si el checkbox es falso
        <h1>No ha aceptado las condiciones para contestar la encuesta</h1>
      )}
    </div>
  );
};

export default EncTeam;
