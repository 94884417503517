import React from "react";
import ReactDOM from "react-dom";

import "./assets/css/index.css";
import "./assets/css/main.css"; // Importa tu archivo CSS de estilos principales aquí
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);