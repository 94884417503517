import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../assets/img/icono.png';
import '../assets/css/styles.css';

const NavbarPage = () => {
  return (
    <Navbar expand="lg" sticky="top" className="navbar-custom">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="logo-img navbar-brand" />
        </Link>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav className="mr-auto">
            <CustomNavLink to="/">Home</CustomNavLink>                                    
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

const CustomNavLink = ({ to, children }) => (
  <Nav.Link as={Link} to={to} className="custom-nav-link">
    {children}
  </Nav.Link>
);

export default NavbarPage;
