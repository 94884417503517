import { useParams } from "react-router-dom";

const ProductDetail = () => {
  const { productId } = useParams();
  console.log(useParams);
  return (
    <>
      <h4>Product Detail Page: {productId}</h4>
    </>
  );
};

export default ProductDetail;
