import Particles from "react-tsparticles";
import particlesConfig from "../assets/js/particlesjs";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgOrg from "../assets/img/001_icono.png";
import imgCli from "../assets/img/002_icono.png";
import imgDes from "../assets/img/003_icono.png";
import imgTorn from "../assets/img/tornado.png";

import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import "../assets/css/home.css";

const Home = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid className="px-0">
      <div className="container-fluid h-100 ffExo">
        <div className="row hcard">
          <div className="col-12 p-0">
            <div className="section section-1">
              <div className="section-1-left"></div>
              <div className="section-1-right">
                <div className="centered-text text-p8">
                  Calidad de redes sociales de trabajo y desempeño de las
                  oficinas de diseño de proyectos de construcción
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row hcard">
          <div className="col-12 p-0">
            <div className="section section-2">
              <div className="section-2-left text-p8">
                <div className="quien_somos_text">Quiénes somos?</div>
                <div className="h6">
                  Somos un grupo de investigación enfocado en explorar el
                  impacto de las redes sociales de trabajo en el desempeño de
                  las oficinas de diseño de proyectos y el rendimiento en la
                  industria de la construcción, tanto dentro como fuera de la
                  organización.
                </div>
              </div>
              <div className="section-2-right"></div>
            </div>
          </div>
        </div>

        <div className="row hcard">
          <div className="col-12 p-0 zInd-2">
            <div className="section section-3 ">
              <div className="section-top">
                <div className="h1" style={{ letterSpacing: "6px" }}>
                  evalúate
                </div>
              </div>
              <div className="section-bottom">
                <div className="bottom-left">
                <Link to={`/encuestas/organizacion/`} className="link-sin-subrayado">
                  <div className="content-wrapper">
                    <div className="top">
                      <div className="image-wrapper">
                        <img
                          src={imgOrg}
                          alt="Organizacion"
                          className="image"
                        />
                      </div>
                    </div>
                    <div className="middle">
                      <div className="h3 num_imagen">1</div>
                    </div>
                    <div className="bottom">
                      <div>
                        <div className="tit_tarjeta">Organización</div>
                        <div className="text_tarjeta">
                          Identifica las interacciones y relaciones entre
                          profesionales en redes de trabajo para mejorar la
                          comunicación y colaboración en oficinas.
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className="bottom-left">
                  <Link to={`/encuestas/cliente/`} className="link-sin-subrayado">
                    <div className="content-wrapper">
                      <div className="top">
                        <div className="image-wrapper">
                          <img src={imgCli} alt="Clientes" className="image" />
                        </div>
                      </div>
                      <div className="middle">
                        <div className="h3 num_imagen">2</div>
                      </div>

                      <div className="bottom">
                        <div>
                          <div className="tit_tarjeta">Cliente</div>
                          <div className="text_tarjeta">
                            Determina las características y necesidades de los
                            clientes para adaptar la oferta de servicios en
                            proyectos de construcción.
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="bottom-left">
                <Link to={`/encuestas/proyecto/`} className="link-sin-subrayado">
                  <div className="content-wrapper">
                    <div className="top">
                      <div className="image-wrapper">
                        <img src={imgDes} alt="Clientes" className="image" />
                      </div>
                    </div>
                    <div className="middle">
                      <div className="h3 num_imagen">3</div>
                    </div>
                    <div className="bottom">
                      <div>
                        <div className="tit_tarjeta">Desempeño</div>
                        <div className="text_tarjeta">
                          Evalúa los indicadores clave de desempeño de trabajo
                          de diseño.
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row hcard">
          <div className="col-12 p-0">
            <div className="section section-4">
              <div className="background-image">
                <div className="imgSect4">
                  <img
                    src={imgTorn}
                    style={{ mixBlendMode: "screen" }}
                    alt="Tornado"
                    width="400"
                    height="300"
                  />
                </div>
                <div className="text-overlay">
                  <div className="h1 slogan">
                    Agradecemos su valiosa participación en la investigación. Su
                    colaboración es fundamental para mejorar la calidad del
                    trabajo en la industria de la construcción.
                  </div>
                  <div className="h1 pt-4">¡Gracias por su colaboración!</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row hcard">
          <div className="col-12 p-0">
            <div className="section section-5">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="section-content h1 zInd-2 jContEnd">
                    Háblanos
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="section-content">
                    <div className="h4 centered-text text-p8">
                      No dude en llamarnos, enviarnos un correo electrónico o
                      contactarnos en nuestras cuentas de redes sociales.
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="contacto-button mt-4">
                        CONTACTENOS
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4 d-flex align-items-center justify-content-center">
                  <div className="section-content">
                    <div>TELEFONO</div>
                    <div>(123) 456-7890</div>
                  </div>
                </div>

                <div className="col-4 d-flex align-items-center justify-content-center">
                  <div className="section-content">
                    <div>EMAIL</div>
                    <div>hello@reallygreatsite.com</div>
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                  <div className="social_net">
                    <a className="social_net" href="https://www.linkedin.com/">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a className="social_net" href="https://twitter.com/">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a className="social_net" href="https://www.facebook.com/">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
