const firebaseConfig = {
  apiKey: "AIzaSyCKYvu_8ED5DiV7eLD2DiNR4HQU8vW-D-8",
  authDomain: "redconstruccion-64b1c.firebaseapp.com",
  databaseURL: "https://redconstruccion-64b1c-default-rtdb.firebaseio.com",
  projectId: "redconstruccion-64b1c",
  storageBucket: "redconstruccion-64b1c.appspot.com",
  messagingSenderId: "139592157781",
  appId: "1:139592157781:web:19d8d6c8f7928411e1a2dc",
  measurementId: "G-8Q7NEE7T7N",
};

export default firebaseConfig;
