const loadAllPaises = [
  {
    country: "Afganistán",
    currency: "afgani",
    symbol: "؋",
    iso_alpha2: "AFN",
    fractional_unit: "pul",
    division: "100",
  },
  {
    country: "Albania",
    currency: "lek",
    symbol: "L",
    iso_alpha2: "ALL",
    fractional_unit: "qindarkë",
    division: "100",
  },
  {
    country: "Reino Unido",
    currency: "libra esterlina",
    symbol: "£",
    iso_alpha2: "GBP",
    fractional_unit: "penique",
    division: "100",
  },
  {
    country: "Argelia",
    currency: "dinar",
    symbol: "د.ج",
    iso_alpha2: "DZD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Andorra",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Angola",
    currency: "kuanza de Angola",
    symbol: "Kz",
    iso_alpha2: "AOA",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Argentina",
    currency: "peso argentino",
    symbol: "$",
    iso_alpha2: "ARS",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Armenia",
    currency: "dram armenio",
    symbol: "",
    iso_alpha2: "AMD",
    fractional_unit: "luma",
    division: "100",
  },
  {
    country: "Aruba",
    currency: "florín arubano",
    symbol: "ƒ",
    iso_alpha2: "AWG",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Australia",
    currency: "dólar australiano",
    symbol: "$",
    iso_alpha2: "AUD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Austria",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Azerbayán",
    currency: "manat azerbayano",
    symbol: "",
    iso_alpha2: "AZN",
    fractional_unit: "qəpik",
    division: "100",
  },
  {
    country: "Bahamas",
    currency: "dólar de las Bahamas",
    symbol: "$",
    iso_alpha2: "BSD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Baréin, Bahrein",
    currency: "dinar de Baréin",
    symbol: ".د.ب",
    iso_alpha2: "BHD",
    fractional_unit: "fils",
    division: "100",
  },
  {
    country: "Bangladés, Bangladesh",
    currency: "taka de Bangladés",
    symbol: "৳",
    iso_alpha2: "BDT",
    fractional_unit: "paisa",
    division: "100",
  },
  {
    country: "Barbados",
    currency: "dólar de las Barbados",
    symbol: "$",
    iso_alpha2: "BBD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Bielorrusia, Belarús",
    currency: "rublo bielorruso",
    symbol: "Br",
    iso_alpha2: "BYR",
    fractional_unit: "kopek",
    division: "100",
  },
  {
    country: "Bélgica",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Belice",
    currency: "dólar beliceño",
    symbol: "$",
    iso_alpha2: "BZD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Bután",
    currency: "gultrum butanés",
    symbol: "Nu.",
    iso_alpha2: "BTN",
    fractional_unit: "chetrum",
    division: "100",
  },
  {
    country: "Bolivia",
    currency: "boliviano",
    symbol: "Bs.",
    iso_alpha2: "BOB",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Bosnia y Herzegovina",
    currency: "marco convertible de Bosnia y Herzegovina",
    symbol: "KM",
    iso_alpha2: "BAM",
    fractional_unit: "fening",
    division: "100",
  },
  {
    country: "Botsuana, Botswana",
    currency: "pula de Botsuana",
    symbol: "P",
    iso_alpha2: "BWP",
    fractional_unit: "tebe",
    division: "100",
  },
  {
    country: "Brasil",
    currency: "real brasileño",
    symbol: "R$",
    iso_alpha2: "BRL",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Brunéi",
    currency: "dólar de Brunei",
    symbol: "$",
    iso_alpha2: "BND",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Bulgaria",
    currency: "leva búlgaro",
    symbol: "лв",
    iso_alpha2: "BGN",
    fractional_unit: "stotinka",
    division: "100",
  },
  {
    country: "Burkina Faso",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Birmania, Myanmar",
    currency: "kiat de Birmania",
    symbol: "K",
    iso_alpha2: "MMK",
    fractional_unit: "pya",
    division: "100",
  },
  {
    country: "Burundi",
    currency: "franco burundés",
    symbol: "Fr",
    iso_alpha2: "BIF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Camboya",
    currency: "riel camboyano",
    symbol: "៛",
    iso_alpha2: "KHR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Canadá",
    currency: "dólar canadiense",
    symbol: "$",
    iso_alpha2: "CAD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Cabo Verde",
    currency: "escudo de Cabo Verde",
    symbol: "Esc, $",
    iso_alpha2: "CVE",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Islas Caimán",
    currency: "dólar de las Islas Caimán",
    symbol: "$",
    iso_alpha2: "KYD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "República Centroafricana",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XAF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Chad",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XAF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Chile",
    currency: "peso chileno",
    symbol: "$",
    iso_alpha2: "CLP",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "China",
    currency: "yuan, renminbi",
    symbol: "¥, 元",
    iso_alpha2: "CNY",
    fractional_unit: "yan, fen",
    division: "100",
  },
  {
    country: "Colombia",
    currency: "peso colombiano",
    symbol: "$",
    iso_alpha2: "COP",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Comores",
    currency: "franco comorano",
    symbol: "Fr",
    iso_alpha2: "KMF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "República Democrática del Congo",
    currency: "franco congoleño",
    symbol: "Fr",
    iso_alpha2: "CDF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "República del Congo",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XAF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Costa Rica",
    currency: "colón costarricense",
    symbol: "₡",
    iso_alpha2: "CRC",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Costa de Marfil, Côte dIvoire",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Croacia",
    currency: "kuna croata",
    symbol: "kn",
    iso_alpha2: "HRK",
    fractional_unit: "lipa",
    division: "100",
  },
  {
    country: "Cuba",
    currency: "peso cubano",
    symbol: "$",
    iso_alpha2: "CUP",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Curação",
    currency: "florín de las Antillas Neerlandesas",
    symbol: "ƒ",
    iso_alpha2: "ANG",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Chipre",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "República Checa",
    currency: "corona checa",
    symbol: "Kč",
    iso_alpha2: "CZK",
    fractional_unit: "haléř",
    division: "100",
  },
  {
    country: "Dinamarca",
    currency: "corona danesa",
    symbol: "kr",
    iso_alpha2: "DKK",
    fractional_unit: "øre",
    division: "100",
  },
  {
    country: "Yibuti, Djibouti",
    currency: "franco yibutiano",
    symbol: "Fr",
    iso_alpha2: "DJF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "República Dominicana",
    currency: "peso dominicano",
    symbol: "$",
    iso_alpha2: "DOP",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Ecuador",
    currency: "dólar estadounidense",
    symbol: "$",
    iso_alpha2: "USD",
    fractional_unit: "Centavos de dólar de Ecuador",
    division: "100",
  },
  {
    country: "Egipto",
    currency: "libra egipcia",
    symbol: "£, ج.م",
    iso_alpha2: "EGP",
    fractional_unit: "piastra",
    division: "100",
  },
  {
    country: "El Salvador",
    currency: "colón salvadoreño",
    symbol: "₡",
    iso_alpha2: "SVC",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Guinea Ecuatorial",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XAF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Eritrea",
    currency: "nakfa de Eritrea",
    symbol: "Nfk",
    iso_alpha2: "ERN",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Estonia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Etiopía",
    currency: "bir",
    symbol: "Br",
    iso_alpha2: "ETB",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Finlandia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Francia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Polinesia Francesa",
    currency: "franco CFP",
    symbol: "Fr",
    iso_alpha2: "XPF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Gabón",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XAF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Gambia",
    currency: "dalasi gambiano",
    symbol: "D",
    iso_alpha2: "GMD",
    fractional_unit: "butut",
    division: "100",
  },
  {
    country: "Georgia",
    currency: "lari georgiano",
    symbol: "ლ",
    iso_alpha2: "GEL",
    fractional_unit: "Tetri",
    division: "100",
  },
  {
    country: "Alemania",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "\nGhana\n[1]\n",
    currency: "cedi de Ghana",
    symbol: "₵",
    iso_alpha2: "GHS",
    fractional_unit: "pesewa",
    division: "100",
  },
  {
    country: "Gibraltar",
    currency: "libra de Gibraltar",
    symbol: "£",
    iso_alpha2: "GIP",
    fractional_unit: "penique",
    division: "100",
  },
  {
    country: "Grecia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Guatemala",
    currency: "quetzal",
    symbol: "Q",
    iso_alpha2: "GTQ",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Guinea",
    currency: "franco guineano",
    symbol: "Fr",
    iso_alpha2: "GNF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Guinea-Bisáu, Guinea Bissau",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Haití",
    currency: "gurde",
    symbol: "G",
    iso_alpha2: "HTG",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Honduras",
    currency: "lempira",
    symbol: "L",
    iso_alpha2: "HNL",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Hungria",
    currency: "forinto",
    symbol: "Ft",
    iso_alpha2: "HUF",
    fractional_unit: "filer",
    division: "100",
  },
  {
    country: "Islandia",
    currency: "corona islandesa",
    symbol: "kr",
    iso_alpha2: "ISK",
    fractional_unit: "eyrir",
    division: "100",
  },
  {
    country: "India",
    currency: "rupia india",
    symbol: "₹",
    iso_alpha2: "INR",
    fractional_unit: "paisa",
    division: "100",
  },
  {
    country: "Indonesia",
    currency: "rupia indonesia",
    symbol: "Rp",
    iso_alpha2: "IDR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Irán",
    currency: "rial iraní",
    symbol: "﷼",
    iso_alpha2: "IRR",
    fractional_unit: "dinar",
    division: "100",
  },
  {
    country: "Irak",
    currency: "dinar irakí",
    symbol: "ع.د",
    iso_alpha2: "IQD",
    fractional_unit: "fils",
    division: "100",
  },
  {
    country: "Irlanda",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Israel",
    currency: "nuevo séquel",
    symbol: "₪",
    iso_alpha2: "ILS",
    fractional_unit: "agora",
    division: "100",
  },
  {
    country: "Italia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Jamaica",
    currency: "dólar jamaicano",
    symbol: "$",
    iso_alpha2: "JMD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Japón",
    currency: "yen",
    symbol: "¥",
    iso_alpha2: "JPY",
    fractional_unit: "sen",
    division: "100",
  },
  {
    country: "Jordania",
    currency: "dinar jordano",
    symbol: "د.ا",
    iso_alpha2: "JOD",
    fractional_unit: "piastra",
    division: "100",
  },
  {
    country: "Kazajistán, Kazajstán",
    currency: "tengue kazajo",
    symbol: "₸",
    iso_alpha2: "KZT",
    fractional_unit: "tiyin",
    division: "100",
  },
  {
    country: "Kenia, Kenya",
    currency: "chelín keniano",
    symbol: "Sh",
    iso_alpha2: "KES",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Corea del Norte",
    currency: "won norcoreano",
    symbol: "₩",
    iso_alpha2: "KPW",
    fractional_unit: "chon",
    division: "100",
  },
  {
    country: "Corea del Sur",
    currency: "won surcoreano",
    symbol: "₩",
    iso_alpha2: "KRW",
    fractional_unit: "yeon",
    division: "100",
  },
  {
    country: "Kuwait",
    currency: "dinar kuwaití",
    symbol: "د.ك",
    iso_alpha2: "KWD",
    fractional_unit: "fils",
    division: "100",
  },
  {
    country: "Kirguzistán",
    currency: "som kirguís",
    symbol: "лв",
    iso_alpha2: "KGS",
    fractional_unit: "tyiyn",
    division: "100",
  },
  {
    country: "Laos",
    currency: "kip",
    symbol: "₭",
    iso_alpha2: "LAK",
    fractional_unit: "att",
    division: "100",
  },
  {
    country: "Letonia",
    currency: "lats",
    symbol: "Ls",
    iso_alpha2: "LVL",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Líbano",
    currency: "libra libanesa",
    symbol: "ل.ل",
    iso_alpha2: "LBP",
    fractional_unit: "piastra",
    division: "100",
  },
  {
    country: "Lesoto, Lesotho",
    currency: "loti de Lesoto",
    symbol: "L",
    iso_alpha2: "LSL",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Liberia",
    currency: "dólar liberiano",
    symbol: "$",
    iso_alpha2: "LRD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Libia",
    currency: "dinar libio",
    symbol: "ل.د",
    iso_alpha2: "LYD",
    fractional_unit: "dírham",
    division: "100",
  },
  {
    country: "Lituania",
    currency: "litas",
    symbol: "Lt",
    iso_alpha2: "LTL",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Luxemburgo",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Macao",
    currency: "pataca de Macao",
    symbol: "P",
    iso_alpha2: "MOP",
    fractional_unit: "avo",
    division: "100",
  },
  {
    country: "Macedonia",
    currency: "denar",
    symbol: "ден",
    iso_alpha2: "MKD",
    fractional_unit: "deni",
    division: "100",
  },
  {
    country: "Madagascar",
    currency: "ariari",
    symbol: "Ar",
    iso_alpha2: "MGA",
    fractional_unit: "iraimbilanja",
    division: "5",
  },
  {
    country: "Malaui, Malawi",
    currency: "kuacha de Malaui",
    symbol: "MK",
    iso_alpha2: "MWK",
    fractional_unit: "tambala",
    division: "100",
  },
  {
    country: "Malasia",
    currency: "ringit",
    symbol: "RM",
    iso_alpha2: "MYR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Maldivas",
    currency: "rufiya",
    symbol: ".ރ",
    iso_alpha2: "MVR",
    fractional_unit: "laari",
    division: "100",
  },
  {
    country: "Mali",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Malta",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Mauritania",
    currency: "uguiya",
    symbol: "UM",
    iso_alpha2: "MRO",
    fractional_unit: "jum",
    division: "5",
  },
  {
    country: "Mauricio",
    currency: "rupia de Mauricio",
    symbol: "₨",
    iso_alpha2: "MUR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "México",
    currency: "peso mexicano",
    symbol: "$",
    iso_alpha2: "MXN",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Estados Federados de Micronesia",
    currency: "dólar micronesio",
    symbol: "$",
    iso_alpha2: "nenhum",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Moldavia",
    currency: "leu moldavo",
    symbol: "L",
    iso_alpha2: "MDL",
    fractional_unit: "ban",
    division: "100",
  },
  {
    country: "Mónaco",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Mongolia",
    currency: "tugrik mongol",
    symbol: "₮",
    iso_alpha2: "MNT",
    fractional_unit: "möngö",
    division: "100",
  },
  {
    country: "Montenegro",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Marruecos",
    currency: "dírham",
    symbol: "د.م.",
    iso_alpha2: "MAD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Mozambique",
    currency: "metical",
    symbol: "MT",
    iso_alpha2: "MZN",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Namibia",
    currency: "dólar de Namibia",
    symbol: "$",
    iso_alpha2: "NAD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Nauru",
    currency: "dólar nauruano",
    symbol: "$",
    iso_alpha2: "Nenhum",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Nepal",
    currency: "rupia nepalí",
    symbol: "₨",
    iso_alpha2: "NPR",
    fractional_unit: "paisa",
    division: "100",
  },
  {
    country: "Países Bajos",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Nueva Caledonia",
    currency: "franco CFP",
    symbol: "Fr",
    iso_alpha2: "XPF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Nueva Zelanda",
    currency: "dólar de Nueva Zelanda",
    symbol: "$",
    iso_alpha2: "NZD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Nicaragua",
    currency: "córdoba nicaragüense",
    symbol: "C$",
    iso_alpha2: "NIO",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Níger",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Nigeria",
    currency: "naira nigeriano",
    symbol: "₦",
    iso_alpha2: "NGN",
    fractional_unit: "kobo",
    division: "100",
  },
  {
    country: "Noruega",
    currency: "corona noruega",
    symbol: "kr",
    iso_alpha2: "NOK",
    fractional_unit: "øre",
    division: "100",
  },
  {
    country: "Omán",
    currency: "rial de Omán",
    symbol: "ر.ع.",
    iso_alpha2: "OMR",
    fractional_unit: "baisa",
    division: "100",
  },
  {
    country: "Paquistán",
    currency: "rupia pakistaní",
    symbol: "₨",
    iso_alpha2: "PKR",
    fractional_unit: "paisa",
    division: "100",
  },
  {
    country: "Panamá",
    currency: "balboa panameño",
    symbol: "B/.",
    iso_alpha2: "PAB",
    fractional_unit: "centésimo",
    division: "100",
  },
  {
    country: "Papua-Nueva Guiné",
    currency: "kina",
    symbol: "K",
    iso_alpha2: "PGK",
    fractional_unit: "toea",
    division: "100",
  },
  {
    country: "Paraguay",
    currency: "guaraní paraguayo",
    symbol: "₲",
    iso_alpha2: "PYG",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Perú",
    currency: "sol",
    symbol: "S/",
    iso_alpha2: "PEN",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Filipinas",
    currency: "peso filipino",
    symbol: "₱",
    iso_alpha2: "PHP",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Polonia",
    currency: "złóti",
    symbol: "zł",
    iso_alpha2: "PLN",
    fractional_unit: "grosz",
    division: "100",
  },
  {
    country: "Portugal",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Catar, Qatar",
    currency: "rial catarí",
    symbol: "ر.ق",
    iso_alpha2: "QAR",
    fractional_unit: "dírham",
    division: "100",
  },
  {
    country: "Rumanía",
    currency: "leu rumano",
    symbol: "L",
    iso_alpha2: "RON",
    fractional_unit: "ban",
    division: "100",
  },
  {
    country: "Rusia",
    currency: "rublo",
    symbol: "₽",
    iso_alpha2: "RUB",
    fractional_unit: "kopek",
    division: "100",
  },
  {
    country: "Ruanda, Rwanda",
    currency: "franco ruandés",
    symbol: "Fr",
    iso_alpha2: "RWF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "San Cristóbal y Nieves, Saint Kitts y Nevis",
    currency: "dólar del Caribe Oriental",
    symbol: "$",
    iso_alpha2: "XCD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Santa Lucía",
    currency: "dólar del Caribe Oriental",
    symbol: "$",
    iso_alpha2: "XCD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "San Viccente y las Granadinas",
    currency: "dólar del Caribe Oriental",
    symbol: "$",
    iso_alpha2: "XCD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Samoa",
    currency: "tala",
    symbol: "T",
    iso_alpha2: "WST",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "San Marino",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Santo Tomé y Príncipe",
    currency: "dobra",
    symbol: "Db",
    iso_alpha2: "STD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Arabia Saudí",
    currency: "rial saudí",
    symbol: "ر.س",
    iso_alpha2: "SAR",
    fractional_unit: "halala",
    division: "100",
  },
  {
    country: "Senegal",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Serbia",
    currency: "dinar serbio",
    symbol: "дин. o din.",
    iso_alpha2: "RSD",
    fractional_unit: "para",
    division: "100",
  },
  {
    country: "Seichelles",
    currency: "rupia seychellense",
    symbol: "₨",
    iso_alpha2: "SCR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Sierra Leona",
    currency: "leona",
    symbol: "Le",
    iso_alpha2: "SLL",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Singapur",
    currency: "dólar singapurense",
    symbol: "$",
    iso_alpha2: "SGD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Eslovaquia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Eslovenia",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Islas Salomón",
    currency: "dólar salomonense",
    symbol: "$",
    iso_alpha2: "SBD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Somalia",
    currency: "chelín somalí",
    symbol: "Sh",
    iso_alpha2: "SOS",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Sudáfrica",
    currency: "rand",
    symbol: "R",
    iso_alpha2: "ZAR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "España",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Sudán del Sur",
    currency: "libra sursudanesa",
    symbol: "£",
    iso_alpha2: "SSP",
    fractional_unit: "piastra",
    division: "100",
  },
  {
    country: "Sri Lanka",
    currency: "rupia ceilandesa",
    symbol: "Rs",
    iso_alpha2: "LKR",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Sudán",
    currency: "libra sudanesa",
    symbol: "£",
    iso_alpha2: "SDG",
    fractional_unit: "piastra",
    division: "100",
  },
  {
    country: "Surinám",
    currency: "dólar surinamés",
    symbol: "$",
    iso_alpha2: "SRD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Suazilandia, Swazilandia",
    currency: "lilangeni",
    symbol: "L",
    iso_alpha2: "SZL",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Suecia",
    currency: "corona sueca",
    symbol: "kr",
    iso_alpha2: "SEK",
    fractional_unit: "öre",
    division: "100",
  },
  {
    country: "Suiza",
    currency: "franco suizo",
    symbol: "Fr",
    iso_alpha2: "CHF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Siria",
    currency: "libra siria",
    symbol: "£ o ل.س",
    iso_alpha2: "SYP",
    fractional_unit: "Piastra",
    division: "100",
  },
  {
    country: "Taiwán",
    currency: "nuevo dólar de Taiwán",
    symbol: "$",
    iso_alpha2: "TWD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Tayikistán",
    currency: "somoni",
    symbol: "ЅМ",
    iso_alpha2: "TJS",
    fractional_unit: "dirame",
    division: "100",
  },
  {
    country: "Tanzania",
    currency: "chelín tanzaniano",
    symbol: "Sh",
    iso_alpha2: "TZS",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Tailandia",
    currency: "bat",
    symbol: "฿",
    iso_alpha2: "THB",
    fractional_unit: "satang",
    division: "100",
  },
  {
    country: "Togo",
    currency: "franco CFA",
    symbol: "Fr",
    iso_alpha2: "XOF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Tonga",
    currency: "paanga",
    symbol: "T$",
    iso_alpha2: "TOP",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Trindade e Tobago",
    currency: "dólar trinitense",
    symbol: "$",
    iso_alpha2: "TTD",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Túnez",
    currency: "dinar tunecino",
    symbol: "د.ت",
    iso_alpha2: "TND",
    fractional_unit: "milésimo",
    division: "100",
  },
  {
    country: "Turquia",
    currency: "lira turca",
    symbol: "",
    iso_alpha2: "TRY",
    fractional_unit: "kuruş",
    division: "100",
  },
  {
    country: "Turkmenistán",
    currency: "manat turcomano",
    symbol: "m",
    iso_alpha2: "TMT",
    fractional_unit: "tennesi",
    division: "100",
  },
  {
    country: "Uganda",
    currency: "chelín ugandés",
    symbol: "Sh",
    iso_alpha2: "UGX",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Ucrania",
    currency: "grivna",
    symbol: "₴",
    iso_alpha2: "UAH",
    fractional_unit: "kopiyka",
    division: "100",
  },
  {
    country: "Emiratos Árabes Unidos",
    currency: "dírham",
    symbol: "د.إ",
    iso_alpha2: "AED",
    fractional_unit: "fils",
    division: "100",
  },
  {
    country: "Estados Unidos",
    currency: "dólar estadounidense",
    symbol: "$",
    iso_alpha2: "USD",
    fractional_unit: "centavo",
    division: "100",
  },
  {
    country: "Uruguay",
    currency: "peso uruguayo",
    symbol: "$",
    iso_alpha2: "UYU",
    fractional_unit: "centésimo",
    division: "100",
  },
  {
    country: "Uzbequistán",
    currency: "sum",
    symbol: "лв",
    iso_alpha2: "UZS",
    fractional_unit: "tiyin",
    division: "100",
  },
  {
    country: "Vanuatu",
    currency: "vatu do Vanuatu",
    symbol: "Vt",
    iso_alpha2: "VUV",
    fractional_unit: "Nenhum",
    division: "Nenhum",
  },
  {
    country: "Ciudad del Vaticano",
    currency: "euro",
    symbol: "€",
    iso_alpha2: "EUR",
    fractional_unit: "cent",
    division: "100",
  },
  {
    country: "Venezuela",
    currency: "bolívar fuerte",
    symbol: "Bs F",
    iso_alpha2: "VEF",
    fractional_unit: "céntimo",
    division: "100",
  },
  {
    country: "Vietnam",
    currency: "dong",
    symbol: "₫",
    iso_alpha2: "VND",
    fractional_unit: "hào",
    division: "10",
  },
  {
    country: "Yemen",
    currency: "rial yemení",
    symbol: "﷼",
    iso_alpha2: "YER",
    fractional_unit: "fils",
    division: "100",
  },
  {
    country: "Zambia",
    currency: "kuacha zambiano",
    symbol: "ZK",
    iso_alpha2: "ZMK",
    fractional_unit: "Ngwee",
    division: "100",
  },
  {
    country: "Zimbabue, Zimbabwe",
    currency: "dólar zimbabuense",
    symbol: "$",
    iso_alpha2: "ZWL",
    fractional_unit: "céntimo",
    division: "100",
  },
];

export default loadAllPaises;
