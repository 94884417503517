import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import ProductDetail from "../components/ProductDetail";
import ProductsList from "../components/ProductsList";
import axios from "axios";

const Product = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios
      .get("https://dummyjson.com/products")
      .then((res) => setProducts(res.data.products));
  });
  return (
    <>
      <h4>Product page</h4>

      <Routes>
        <Route
          index
          element={<ProductsList products={products}></ProductsList>}
        ></Route>
        <Route path=":productId" element={<ProductDetail />}></Route>
      </Routes>
    </>
  );
};

export default Product;
